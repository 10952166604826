import React from "react";
import styles from "./production_graph.module.css";
import ProductionChart from "./ProductionChart";
import i18n from "i18n-js";

const ProductionGraph = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        {i18n.t("productionGraph").toUpperCase()}
      </div>
      <div className={styles.graph_container}>
        <ProductionChart
          data={props.graph.data.series}
          meta={props.graph.meta}
        />
      </div>
    </div>
  );
};

export default ProductionGraph;
