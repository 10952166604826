import React from "react";
import moment from "moment";
import styles from "./header.module.css";

const Header = (props) => {
  return (
    <div>
      <div className={styles.header_row_container}>
        <div className={`dsr_vendor_logo ${styles.header_logo}`} />
        <div className={`dsr_company_logo ${styles.header_logo}`} />
      </div>
      <div className={styles.header_row_container}>
        <div className={styles.station_title}>{props.station.name}</div>
        <div className={styles.report_date}>
          {moment(props.reportDate).format("DD MMMM Y").toUpperCase()}
        </div>
      </div>
      <div className={styles.horizontal_divider} />
    </div>
  );
};

export default Header;
