import React from "react";
import moment from "moment";
import i18n from "i18n-js";

import {
  VendorFooterLogo,
  VendorHeaderLogo,
  usePageLayout,
  setLocale,
  VendorLogo,
} from "../../utils";
import {
  EventLogsTable,
  Header,
  PortalDataTable,
  PortalVsAMRTable,
  ProductionGraph,
  Sheet,
} from "./components";

const DailyStationReportTemplate = (props) => {
  const { report } = props;
  const { report_date, station, created_at, meta, data } = report;

  usePageLayout("A4", "portrait");
  setLocale(meta.language);

  const getLogo = () => {
    if (station.logo) return station.logo;
    if (station.company.logo) return station.company.logo;
    return VendorHeaderLogo[meta.vendor_id];
  };

  document.title = "".concat(
    `${station.name}`,
    " - ",
    i18n.t("dailySppPerformanceReport")
  );

  return (
    <React.Fragment>
      <style>
        {`
        .solarify_logo { flex: 2; text-align: center;}
        .solarify_logo::before {content: url(${
          VendorFooterLogo[meta.vendor_id]
        })}
        body{ counter-reset: page_counter headings }
        .report_date:before{ content:'${moment(created_at).format(
          "DD.MM.YYYY"
        )}' }
        @page { size: A4 portrait; }
        .dsr_vendor_logo { content: url("${VendorLogo[meta.vendor_id]}") }
        .dsr_company_logo { content: url("${getLogo()}") }
      `}
      </style>

      <Sheet>
        <Header station={station} reportDate={report_date} />
        <PortalDataTable
          data={data.station_metrics}
          reportDate={report_date}
        />
        {data.station_has_amr_datasource && (
          <PortalVsAMRTable
            data={data.station_metrics}
            amrData={data.station_amr_metrics}
            reportDate={report_date}
          />
        )}
        <ProductionGraph graph={data.graph} />
      </Sheet>
      <Sheet>
      <EventLogsTable
          tickets={data.event_logs.tickets}
          alarms={data.event_logs.alarms}
        />
      </Sheet>
    </React.Fragment>
  );
};

export default DailyStationReportTemplate;
