import React from "react";
import styles from "./event_logs.module.css";
import i18n from "i18n-js";

const SEVERITY_LEVELS = ["low", "medium", "high", "critical"];
const STATUSES = ["opened", "on_going", "closed"];
const COLORS = { opened: "red", on_going: "blue", closed: "green" };

const EventLogsTable = ({ tickets, alarms }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        {i18n.t("eventLogs").toUpperCase()}
      </div>
      <div className={styles.table_container}>
        <table className={styles.el_table}>
          <thead>
            <tr className={styles.table_header_group}>
              <th></th>
              <th colSpan={4}>
                <div className={styles.parent_header}>
                  {i18n.t("alarm").toUpperCase()}
                </div>
              </th>
              <th colSpan={4}>
                <div className={styles.parent_header}>
                  {i18n.t("ticketing").toUpperCase()}
                </div>
              </th>
            </tr>
            <tr className={styles.table_header}>
              <th></th>
              {SEVERITY_LEVELS.concat(SEVERITY_LEVELS).map((level, idx) => (
                <th key={idx}>
                  <div className={styles.table_header_cell}>
                    {i18n.t(level).toUpperCase()}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {STATUSES.map((status) => (
              <tr key={status} className={styles.table_body}>
                <td>
                  <div className={styles.el_table_row}>
                    <div className={`${styles.title_border} ${styles[COLORS[status]]}`}/>
                    <div className={`${styles.row_title} ${styles[COLORS[status]]}`}>
                      {i18n.t(status).toUpperCase()}
                    </div>
                  </div>
                </td>
                {SEVERITY_LEVELS.map((level) => (
                  <td key={`alarm-${status}-${level}`}>
                    <div className={`${styles.cell} ${styles[COLORS[status]]}`}>
                      {alarms[status]?.[level] || "—"}
                    </div>
                  </td>
                ))}
                {SEVERITY_LEVELS.map((level) => (
                  <td key={`ticket-${status}-${level}`}>
                    <div className={`${styles.cell} ${styles[COLORS[status]]}`}>
                      {tickets[status]?.[level] || "—"}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
            <tr className={styles.table_footer}>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td colSpan={4}>
                <div className={styles.deepnote}>
                  {i18n.t("dailyTicketCountDeepnote")}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EventLogsTable;
