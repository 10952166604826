import React from "react";
import HighchartsReact from "highcharts-react-official";
import merge from "lodash/merge";
import numeral from "numeral";
import { isEmpty } from "../../../../utils";

const defaultConfig = {
  chart: {
    width: 760,
    height: 350,
  },
  credits: {
    enabled: false,
  },
  title: {
    text: null,
  },
  xAxis: {
    type: "datetime",
  },
  yAxis: [],
  tooltip: {
    shared: true,
    headerFormat:
      '<span style="font-size: 10px"><b>{point.key}</b></span><br/>',
    xDateFormat: "%d/%m/%Y %H:%M",
    pointFormatter() {
      return `<span style="font-size: 16px; color:${
        this.color
      }">\u25CF</span> ${this.series.name}: <b>${numeral(this.y).format(
        "0.00"
      )}</b><br/>`;
    },
  },
  plotOptions: {
    series: {
      connectNulls: false,
      marker: {
        enabled: false,
      },
    },
    spline: {
      connectNulls: false,
    },
  },
  series: [],
};

const ProductionChart = (props) => {
  const findMaxTick = (value, limit) => {
    if (limit < 100) return limit;

    const max = Math.round(value / 100 + 1) * 100;

    return max < limit ? limit : max;
  };

  const { data, meta } = props;
  const opt = {};

  if (!isEmpty(data) && !isEmpty(meta)) {
    opt.xAxis = {
      min: meta.start,
      max: meta.end,
      labels: {
        style: {
          color: "#000000",
        },
      },
    };
    opt.time = {
      timezoneOffset: meta.timezone_offset,
    };
    opt.tooltip = {
      xDateFormat: meta.chart.date_format,
    };

    const series = [];
    const yAxis = [];
    let yAxisCount = 0;

    if (data.energy) {
      series.push({
        name: meta.chart.units.energy,
        data: data.energy.map((row) => ({
          x: row[0],
          y: row[1],
        })),
        type: meta.chart.series_type,
        color: meta.chart.colors.energy,
        yAxis: 0,
      });

      yAxisCount += 1;

      yAxis.push({
        title: {
          text: meta.chart.units.energy,
          style: {
            color: "#000000",
            fontWeight: "500",
          },
        },
        max: findMaxTick(meta.max_energy, meta.nominal_power),
        min: 0,
        gridLineDashStyle: "Dash",
        tickPixelInterval: 30,
      });
    }
    if (data.irradiance) {
      series.push({
        name: meta.chart.units.irradiance,
        data: data.irradiance.map((row) => ({
          x: row[0],
          y: row[1],
        })),
        type: meta.chart.series_type,
        color: meta.chart.colors.irradiance,
        yAxis: yAxisCount,
      });

      yAxisCount += 1;

      yAxis.push({
        title: {
          text: meta.chart.units.irradiance,
          style: {
            color: "#000000",
            fontWeight: "500",
          },
        },
        max: findMaxTick(meta.max_irradiance, 1000),
        min: 0,
        gridLineDashStyle: "Dash",
        opposite: true,
        tickPixelInterval: 30,
      });
    }
    if (data.temperature) {
      series.push({
        name: meta.chart.units.temperature,
        data: data.temperature.map((row) => ({
          x: row[0],
          y: row[1],
        })),
        type: meta.chart.series_type,
        color: meta.chart.colors.temperature,
        yAxis: yAxisCount,
      });

      yAxis.push({
        title: {
          text: meta.chart.units.temperature,
          style: {
            color: "#000000",
            fontWeight: "500",
          },
        },
        max: 70,
        min: 0,
        gridLineDashStyle: "Dash",
        opposite: true,
        tickPixelInterval: 30,
      });
    }

    opt.series = series;
    opt.yAxis = yAxis;
  }

  return (
    <React.Fragment>
      <HighchartsReact options={merge({}, defaultConfig, opt)} />
    </React.Fragment>
  );
};

export default ProductionChart;
