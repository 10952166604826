import React from "react";
import moment from "moment";
import styles from "./portal_vs_amr.module.css";
import i18n from "i18n-js";
import { formatKWH } from "../../../../utils";

const PortalVsAMRTable = ({ data, amrData, reportDate }) => {
  const date = moment(reportDate);
  const dateLabels = {
    yesterday: date.clone().subtract(1, "days").format("DD MMMM").toUpperCase(),
    today: date.format("DD MMMM").toUpperCase(),
    monthly: date.format("MMMM").toUpperCase(),
    yearly: date.format("YYYY"),
  };

  const renderRow = (titleKey, data, color) => (
    <tr className={styles.table_body}>
      <td>
        <div className={styles.pva_table_row}>
          <div className={`${styles.title_border} ${styles[color]}`} />
          <div className={`${styles.row_title} ${styles[color]}`}>
            {i18n.t(titleKey).toUpperCase()}
          </div>
        </div>
      </td>
      {Object.keys(dateLabels).map((key, idx) => (
        <td key={idx}>
          <div className={`${styles.cell} ${styles[color]}`}>
            {data[key]?.value
              ? `${formatKWH(data[key].value)} ${data[key].unit}`
              : "—"}
          </div>
        </td>
      ))}
    </tr>
  );

  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        {i18n.t("portalAmrComparison").toUpperCase()}
      </div>
      <div className={styles.table_container}>
        <table className={styles.pva_table}>
          <thead>
            <tr className={styles.table_header}>
              <th></th>
              {Object.values(dateLabels).map((label, idx) => (
                <th key={idx}>
                  <div className={styles.table_header_cell}>{label}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {renderRow("production", data.production, "green")}
            {renderRow("amrProduction", amrData, "pink")}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PortalVsAMRTable;
