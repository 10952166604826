import React, { useState, useEffect } from 'react';

import {
  TicketReportTemplate,
  EmptyTemplate,
  MonthlyStationReportTemplate,
  TaskManagementTemplate,
  DailyStationReportTemplate
} from './templates';
import { getUrl } from './utils';

export default function ReportView(props) {
  const [state, setState] = useState({ response: {}, loading: true });
  const reportId = props.match.params.uid;

  useEffect(() => {

    fetch(getUrl(`/api/reports/${reportId}/`))
      .then(response => {
        if (response.status !== 200) {
          throw Error(response.statusText)
        }
        return response.json()
      })
      .then((response) => {
        setState({ response, loading: false })
      })
      .catch(error => {
        setState({ response: {}, loading: false })
        console.error('Error:', error)
      })
  }, [reportId])

  if (state.loading) {
    return <div className="loader">Loading...</div>
  }

  switch (state.response.type) {
    case 'ticket':
      return <TicketReportTemplate report={state.response} />;
    case 'monthly-station':
      return <MonthlyStationReportTemplate report={state.response} />
    case 'task-management':
      return <TaskManagementTemplate report={state.response} />
    case 'daily-station':
      return <DailyStationReportTemplate report={state.response} />
    default:
      return <EmptyTemplate />;
  }
}
