import React from "react";
import moment from "moment";
import styles from "./portal_data.module.css";
import i18n from "i18n-js";
import { formatKWH } from "../../../../utils";

const PortalDataTable = ({ data, reportDate }) => {
  const {
    production,
    production_average,
    irradiance,
    performance_ratio,
    corrected_performance_ratio,
  } = data;

  const date = moment(reportDate);
  const reportDateFormatted = date.format("DD MMMM").toUpperCase();
  const yesterday = date.subtract(1, "days").format("DD MMMM").toUpperCase();
  const monthName = date.format("MMMM").toUpperCase();
  const yearText = date.format("YYYY");

  const dateLabels = {
    yesterday,
    today: reportDateFormatted,
    monthly: monthName,
    yearly: yearText,
  };

  const TableRow = ({
    title,
    values,
    klassName,
    formatter = (v) => v,
    isPerformanceRatio = false,
  }) => {

    const renderValue = (label, values) => {
      const data = values[label];

      if (isPerformanceRatio) {
        const tprData = corrected_performance_ratio[label];
        return `${data?.value || "—"}${data?.unit} / ${tprData?.value || "—"}${
          tprData?.unit
        }`;
      }

      return data?.value ? `${formatter(data.value)} ${data.unit}` : "—";
    };

    return (
      <tr className={styles.table_body}>
        <td>
          <div className={styles.pd_table_row}>
            <div className={`${styles.title_border} ${klassName}`} />
            <div className={`${styles.row_title} ${klassName}`}>
              {i18n.t(title).toUpperCase()}
            </div>
          </div>
        </td>
        {["yesterday", "today", "monthly", "yearly"].map((label, idx) => (
          <td key={idx}>
            <div className={`${styles.cell} ${klassName}`}>
              {renderValue(label, values)}
            </div>
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.container_title}>
        {i18n.t("portalData").toUpperCase()}
      </div>
      <div className={styles.table_container}>
        <table className={styles.pd_table}>
          <thead>
            <tr className={styles.table_header}>
              <th></th>
              {["yesterday", "today", "monthly", "yearly"].map((label, idx) => (
                <th key={idx}>
                  <div className={styles.table_header_cell}>
                    {dateLabels[label]}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <TableRow
              title="production"
              values={production}
              klassName={styles.green}
              formatter={formatKWH}
            />
            <TableRow
              title="dailyProductionAvg"
              values={production_average}
              klassName={styles.blue}
              formatter={formatKWH}
            />
            <TableRow
              title="irradiance"
              values={irradiance}
              klassName={styles.orange}
              formatter={formatKWH}
            />
            <TableRow
              title="performanceRatioWithTemperature"
              values={performance_ratio}
              klassName={styles.purple}
              isPerformanceRatio
            />
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PortalDataTable;
